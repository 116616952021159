import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '@/components/UserLogin.vue';
import MainPanel from '@/components/MainPanel.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';
import OperationsModule from '@/components/OperationsModule.vue';
import NewOrder from '@/components/NewOrder.vue';
import NewOrderAssistance from '@/components/NewOrderAssistance.vue';
import CreateNewOrder from '@/components/CreateNewOrder.vue';
import MainMap from '@/components/MainMap.vue';
import TrackMap from '@/components/TrackMap.vue';
import NoteCreationMap from '@/components/NoteCreationMap.vue';
import PointTrackMap from '@/components/PointTrackMap.vue';
import PriceService from '@/components/PriceService.vue';
import NewOrderAuxiliar from '@/components/NewOrderAuxiliar.vue'
import NewOrderForklift from '@/components/NewOrderForklift.vue';
import OperatorInterface from '@/components/OperatorInterface.vue';
import EditAssignation from '@/components/EditAssignation.vue';
import NewOrderLot from '@/components/NewOrderLot.vue';
import PricesRequests from '@/components/PricesRequests.vue';
import EditNote from '@/components/EditNote.vue';
import ResponsiveMap from '@/components/ResponsiveMap.vue';
import StatusUnits from '@/components/StatusUnits.vue';
import MaintenanceModule from '@/components/MaintenanceModule.vue';
import AddNewUnit from '@/components/AddNewUnit';
import UploadFile from '@/components/upload-file';
import RetrieveFile from '@/components/retrieve-file'
import RHModule from '@/components/RHModule';
import AddNewEmployee from '@/components/AddNewEmployee';
import EditProfile from '@/components/EditProfile.vue';
import GlobalFilters from '@/components/GlobalFilters.vue';
import EditUnitProfile from '@/components/EditUnitProfile.vue';

const routes = [
    {
        path: '/',
        name: 'UserLogin',
        component: UserLogin,
        meta: { requiresAuth: false}
    },
    {
        path: '/main-panel',
        name: 'MainPanel',
        component: MainPanel,
        meta: {requiresAuth: true}
    },
    {
        path: '/under-construction',
        name: 'UnderConstruction',
        component: UnderConstruction,
        meta: {requiresAuth: true}
    },
    {
        path: '/operations-module',
        name: 'OperationsModule',
        component: OperationsModule,
        meta: {requiresAuth: true}
    },
    {
        path: '/new-order',
        name: 'NewOrder',
        component: NewOrder,
        meta: {requiresAuth: true}
    },
    {
        path: '/create-new-order',
        name: 'CreateNewOrder',
        component: CreateNewOrder,
        meta: {requiresAuth: true}
    },
    {
        path: '/new-order-assistance',
        name: 'NewOrderAssistance',
        component: NewOrderAssistance,
        meta: {requiresAuth: true}
    },
    {
        path: '/main-map',
        name: 'MainMap',
        component: MainMap,
        meta: {requiresAuth: true}
    },
    {
        path: '/track-map',
        name: 'TrackMap',
        component: TrackMap,
        meta: {requiresAuth: true}
    },
    {
        path: '/note-creation-map',
        name: 'NoteCreationMap',
        component: NoteCreationMap,
        meta: {requiresAuth: true}
    },
    {
        path: '/point-track-map',
        name: 'PointTrackMap',
        component: PointTrackMap,
        meta: {requiresAuth: true}
    },
    {
        path: '/cotizar',
        name: 'cotizar',
        component: PriceService,
        meta: {requiresAuth: false}
    },
    {
        path: '/new-order-auxiliar',
        name: 'NewOrderAuxiliar',
        component: NewOrderAuxiliar,
        meta: {requiresAuth: true}
    },
    {
        path: '/new-order-forklift',
        name: 'NewOrderForklift',
        component: NewOrderForklift,
        meta: {requiresAuth: true}
    },
    {
        path: '/operator-interface',
        name: 'OperatorInterface',
        component: OperatorInterface,
        meta: {requiresAuth: true}
    },
    {
        path: '/assignation/:assignation_id',
        name: 'EditAssignation',
        props: true,
        component: EditAssignation,
        meta: {requiresAuth: true}
    },
    {
        path: '/new-order-lot',
        name: 'NewOrderLot',
        component: NewOrderLot,
        meta: {requiresAuth: true}
    },
    {
        path: '/prices-requests',
        name: 'PricesRequests',
        component: PricesRequests,
        meta: {requiresAuth: true}
    },
    {
        path: '/notes/:noteID',
        name: 'EditNote',
        component: EditNote,
        props: true, // This allows the component to receive the noteID as a prop
        meta: { requiresAuth: true } // Assuming you want this page to be protected
    },
    {
        path: '/ver-estatus-unidades',
        name: 'StatusUnits',
        component: StatusUnits,
        meta: {requiresAuth: true}
    },
    {
        path: '/responsive-map',
        name: 'ResponsiveMap',
        component: ResponsiveMap,
        meta: {requiresAuth: true}
    },
    {
        path: '/modulo-mantenimiento',
        name: 'MaintenanceModule',
        component: MaintenanceModule,
        meta: { requiresAuth: true } // Assuming you want this page to be protected
    },
    {
        path: '/agregar-nueva-unidad',
        name: 'AddNewUnit',
        component: AddNewUnit,
        meta: {requiresAuth: true}
    },
    {
        path : '/test-upload',
        name: 'UploadFile',
        component: UploadFile,
        meta: {requiresAuth: true}
    },
    {
        path : '/test-retrieve',
        name: 'RetrieveFile',
        component: RetrieveFile,
        meta: {requiresAuth: true}
    },
    {
        path: '/modulo-personal',
        name: 'RHModule',
        component: RHModule,
        meta: { requiresAuth: true }
    },
    {
        path: '/agregar-nuevo-personal',
        name: 'AddNewEmployee',
        component: AddNewEmployee,
        meta: {requiresAuth: true}
    },
    {
        path: '/employee_details/:employee_ident',
        name: 'EditProfile',
        component: EditProfile,
        props: true, // This allows the component to receive the noteID as a prop
        meta: { requiresAuth: true } // Assuming you want this page to be protected
    },
    {
        path: '/filtros-globales',
        name: 'GlobalFilters',
        component: GlobalFilters,
        meta: {requiresAuth: true}
    },
    {
        path: '/unit_details/:unit_id',
        name: 'EditUnitProfile',
        component: EditUnitProfile,
        props: true, // This allows the component to receive the noteID as a prop
        meta: { requiresAuth: true } // Assuming you want this page to be protected
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = localStorage.getItem('authToken');

    if (requiresAuth && !isAuthenticated) {
        next({ name: 'UserLogin' });
    } else if (!requiresAuth && isAuthenticated && to.name === 'UserLogin') {
        next({ name: 'MainPanel' });
    } else {
        next();
    }
});

export default router